.App {
  text-align: center;
  overflow: hidden;
}

a {
  color: inherit !important;
}

#notFound {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

#home {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  overflow: hidden;
}

#scrollbutton {
  position: absolute;
  bottom: 0;
}

#code-img {
  animation: scroll 100s ease-in-out infinite alternate;
}
@keyframes scroll {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-160%);
  }
}

/* for scroll button animation */
#dark1,
#light1,
#dark2 {
  transition: all 1s ease;
}
#dark2 {
  transform: translateY(-100%);
}
#scrollbutton:hover #light1 {
  transform: translateY(20%);
}
#scrollbutton:hover #dark1 {
  transform: translateY(40%);
  opacity: 0;
}
#scrollbutton:hover #dark2 {
  transform: translateY(5%);
}

#about {
  min-height: 100vh;
  display: flex;
  align-items: center;
  text-align: left;
}

#badge {
  max-height: 100px;
  margin: 10px;
}

#projects {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
}

@keyframes wipe-enter {
  0% {
    transform: scale(0, 0.025);
  }
  50% {
    transform: scale(1, 0.025);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .card-animation {
    animation: wipe-enter 1s 1;
  }
}

.project-card,
.project-card > button {
  height: 100%;
  cursor: auto;
}

.project-card > button > div > a:nth-child(5) {
  margin-left: 0.5rem !important;
}

.media-card {
  position: absolute;
  width: 50%;
  height: 100%;
  opacity: 0.2;
  background-position: right !important;
}

#contact {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
}

.contactform {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 64ch;
}

.form_group_recaptcha > div {
  display: flex;
  place-content: center;
}

#footer {
  margin: 20px;
}

.social {
  color: black;
  text-decoration: none;
  margin: 0 1rem;
  transition: 0.5s;
}

.social:hover {
  color: blue !important;
}
